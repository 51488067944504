import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// layouts
import MainLayout from 'src/layouts/main';

// components
import { SplashScreen } from 'src/components/loading-screen';
import { AuthGuard } from 'src/auth/guard';

// ----------------------------------------------------------------------

export const HomePage = lazy(() => import('src/pages/home'));

const Page404 = lazy(() => import('src/pages/404'));

const ContactPage = lazy(() => import('src/pages/contact-us'));

const CommissionCalculator = lazy(() => import('src/pages/commission-calculator'));

// ----------------------------------------------------------------------

export const mainRoutes = [
  {
    element: (
      <AuthGuard>
        <MainLayout>
          <Suspense fallback={<SplashScreen />}>
            <Outlet />
          </Suspense>
        </MainLayout>
      </AuthGuard>
    ),
    children: [
      { path: 'commission-calculator', element: <CommissionCalculator /> },
      { path: 'get-help', element: <ContactPage /> },
    ],
  },
];
