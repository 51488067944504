import { lazy } from 'react';

// layouts
import AuthClassicLayout from 'src/layouts/auth/classic';
// components

// ----------------------------------------------------------------------

// JWT
const JwtLoginPage = lazy(() => import('src/pages/auth/jwt/login'));

// ----------------------------------------------------------------------

export const authRoutes = [
  {
    children: [
      {
        path: '/login',
        element: (
          <AuthClassicLayout>
            <JwtLoginPage />
          </AuthClassicLayout>
        ),
      },
    ],
  },
];
