import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
// @mui
import Link from '@mui/material/Link';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
// routes
import { RouterLink } from 'src/routes/components';
// components
import Iconify from 'src/components/iconify';
//
import { ListItem } from './styles';

// ----------------------------------------------------------------------

export default function NavItem({ item, open, active, externalLink, onClick, ...other }) {
  const { title, path, icon, children, onPage } = item;
  const navigate = useNavigate();

  const handleScroll = (event, id) => {
    // console.log('LOCATION:', window.location);
    id = id.replace('#', '');
    event.preventDefault();

    if (window.location.pathname === '/') {
      // Prevent default anchor behavior
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        onClick();
      }
    } else {
      // console.log('WRITE CODE');
      navigate('/');
      setTimeout(() => {
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' });
          onClick();
        }
      }, 300);
    }
  };

  const renderContent = (
    <ListItem active={active} {...other}>
      <ListItemIcon> {icon} </ListItemIcon>

      <ListItemText disableTypography primary={title} />

      {!!children && (
        <Iconify
          width={16}
          icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
          sx={{ ml: 1 }}
        />
      )}
    </ListItem>
  );

  // External link
  if (externalLink) {
    return (
      <Link href={path} target="_blank" rel="noopener" underline="none">
        {renderContent}
      </Link>
    );
  }

  // Has child
  if (children) {
    return renderContent;
  }

  if (onPage) {
    return (
      <Link underline="none" onClick={(e) => handleScroll(e, path)}>
        {renderContent}
      </Link>
    );
  }

  // Default
  return (
    <Link component={RouterLink} href={path} underline="none">
      {renderContent}
    </Link>
  );
}

NavItem.propTypes = {
  active: PropTypes.bool,
  externalLink: PropTypes.bool,
  item: PropTypes.object,
  open: PropTypes.bool,
  onClick: PropTypes.func,
};
