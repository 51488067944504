// @mui
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Badge, { badgeClasses } from '@mui/material/Badge';
// hooks
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';
import { AuthContext, AuthProvider } from 'src/auth/context/jwt';
import { useAuthContext } from 'src/auth/hooks';
// theme
import { bgBlur } from 'src/theme/css';
// routes
import { paths } from 'src/routes/paths';
// components
import Logo from 'src/components/logo';
//
import { HEADER } from '../config-layout';
import { navConfig } from './config-navigation';
import NavMobile from './nav/mobile';
import NavDesktop from './nav/desktop';
//
import AccountPopover from './account-popover';

// ----------------------------------------------------------------------

export default function Header() {
  const { authenticated, method, user } = useAuthContext();
  // console.log('users', user, authenticated);

  // const loginUser = sessionStorage.getItem('user');
  // console.log(loginUser);
  const theme = useTheme();

  const mdUp = useResponsive('up', 'md');
  // const user = sessionStorage.getItem('user');
  // console.log('user', user.data);

  const offsetTop = useOffSetTop(HEADER.H_DESKTOP);

  return (
    <AppBar>
      <Toolbar
        disableGutters
        sx={{
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_DESKTOP,
          },
          ...bgBlur({
            color: theme.palette.background.default,
          }),
          color: theme.palette.background.default,
          transition: theme.transitions.create(['height'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...(offsetTop && {
            ...bgBlur({
              color: theme.palette.background.default,
            }),
            height: {
              md: HEADER.H_DESKTOP,
              xs: HEADER.H_MOBILE,
            },
          }),
        }}
      >
        <Box
          sx={{
            height: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            marginLeft: { xs: '1rem', sm: '2rem', md: '3rem', lg: '3rem' },
            marginRight: { xs: '1rem', sm: '2rem', md: '3rem', lg: '3rem' },
          }}
        >
          <Logo />

          {/* <Box sx={{ flexGrow: 1 }} /> */}

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {mdUp && <NavDesktop offsetTop={offsetTop} data={navConfig} />}

            <Stack alignItems="center" direction={{ xs: 'row', md: 'row-reverse' }}>
              <AccountPopover />

              {!mdUp && <NavMobile offsetTop={offsetTop} data={navConfig} />}
            </Stack>
          </Box>
        </Box>
      </Toolbar>

      {/* {offsetTop && <HeaderShadow />} */}
    </AppBar>
  );
}
