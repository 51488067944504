import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';

// ----------------------------------------------------------------------

export default function AuthClassicLayout({ children, image }) {
  const upMd = useResponsive('up', 'md');
  const renderContent = (
    <Stack
      sx={{
        width: 1,
        mx: 'auto',
        maxWidth: 480,
        px: { xs: 2, md: 1, lg: 2 },
        py: { xs: 15, md: 30 },
      }}
    >
      {children}
    </Stack>
  );

  const renderSection = (
    <Stack flexGrow={1} alignItems="center" justifyContent="center" spacing={5}>
      <Stack>
        <Typography
          sx={{
            maxWidth: 480,
            textAlign: 'center',
            fontSize: '32px',
            fontWeight: '700',
            lineHeight: 'normal',
            mt: 5,
          }}
        >
          Welcome back
        </Typography>
        <Typography
          sx={{
            maxWidth: 340,
            textAlign: 'center',
            fontSize: '24px',
            fontWeight: '200',
            lineHeight: 'normal',
          }}
        >
          Kayo <span style={{ color: '#00C9FF' }}>Blue</span>
        </Typography>
      </Stack>
      <Box
        component="img"
        alt="auth"
        src={image || '/assets/illustrations/illustration_dashboard.png'}
        sx={{ maxWidth: 520 }}
      />
    </Stack>
  );

  return (
    <Grid
      container
      spacing={6}
      sx={{
        minHeight: '100vh',
        backgroundColor: '#98A5B114',
      }}
    >
      <Grid
        item
        xs={0}
        sm={0}
        md={7}
        lg={8}
        sx={{ minHeight: { xs: '0vh', sm: '0vh', md: '100vh', lg: '100vh' } }}
      >
        {upMd && renderSection}
      </Grid>
      <Grid
        item
        md={5}
        lg={4}
        sm={12}
        xs={12}
        sx={{
          minHeight: '100vh',
          background:
            'linear-gradient(230deg, rgba(0, 184, 217, 0.20) 0%, rgba(0, 0, 0, 0.00) 59.46%), var(--s2-bg, #1E242D)',
        }}
      >
        {renderContent}
      </Grid>
    </Grid>
  );
}

AuthClassicLayout.propTypes = {
  children: PropTypes.node,
  image: PropTypes.string,
};
